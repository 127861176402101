<template>
  <v-app>
    <splash-screen v-if="currentUser" />
    <intract-app-bar v-if="currentUser" />

    <div class="main" id="main">
      <v-main>
        <transition name="slide-fade" mode="out-in" @before-leave="beforeLeave">
          <keep-alive :key="$route.fullPath" :include="allKeepAliveRoutes">
            <router-view class="view" id="intract-router-view" />
          </keep-alive>
        </transition>
      </v-main>
    </div>
    <intract-snackbar />
    <bottom-bar v-if="currentUser" />
    <!-- <offline @detected-condition="s => connected = s"></offline> -->
    <div
      v-if="isOffline"
      class="no-internet-connection-bottom text-center mb-2"
    >
      <v-icon class="mr-2" color="white">mdi-alert-octagon</v-icon>
      <p class="pa-0 my-2 no-internet-text font-weight-medium">
        No internet connection
      </p>
    </div>

    <!-- disabled user dialog -->
    <v-dialog
      v-if="currentUser"
      v-model="currentUser.disabled"
      persistent
      width="400"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 red--text">
          Account Disabled
        </v-card-title>
        <v-card-text class="mt-2 font-weight-bold">
          <div v-if="currentUser.is_admin">
            <p class="ma-0 text-caption">Your account has been disabled.</p>
          </div>
          <div v-else>
            Your account has been disabled. Please contact the school admin.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="window.location.reload()">
            <v-icon>mdi-reload</v-icon>
            Check again
          </v-btn>
          <v-btn
            v-if="currentUser.is_admin"
            small
            @click="showSupportSheet = true"
            color="primary"
            outlined
            >Contact Support</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- disabled institution dialog -->
    <v-dialog
      v-if="currentInstitution"
      v-model="currentInstitution.disabled"
      persistent
      width="400"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 red--text">
          Institution Disabled
        </v-card-title>
        <v-card-text class="mt-2 py-0 font-weight-bold">
          <div v-if="currentUser.is_admin">
            <p class="mb-0 text-caption">Your institution has been disabled.</p>
          </div>
          <div v-else>
            <p class="mb-0">
              Your institution has been disabled. Please contact the school
              admin.
            </p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined small @click="window.location.reload()">
            <v-icon>mdi-reload</v-icon>
            Check again
          </v-btn>
          <v-btn
            v-if="currentUser.is_admin"
            small
            @click="showSupportSheet = true"
            color="primary"
            outlined
            >Contact Support</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- contact support sheet -->
    <v-bottom-sheet
      scrollable
      persistent
      :width="$vuetify.breakpoint.mdAndDown ? '100%' : '60%'"
      :value="showSupportSheet"
      @click:outside="showSupportSheet = false"
    >
      <v-card>
        <v-card-title class="pb-2">
          Contact Support<v-spacer></v-spacer>

          <v-btn icon @click="showSupportSheet = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <div class="mb-4">
          <v-list-item href="tel:+917900087696">
            <v-list-item-avatar><v-icon>mdi-phone</v-icon></v-list-item-avatar>
            <v-list-item-title>+91 79000 87696</v-list-item-title>
            <v-list-item-action
              ><v-icon>mdi-arrow-right</v-icon></v-list-item-action
            >
          </v-list-item>
          <v-list-item href="tel:+919082573219">
            <v-list-item-avatar><v-icon>mdi-phone</v-icon></v-list-item-avatar>
            <v-list-item-title>+91 90825 73219</v-list-item-title>
            <v-list-item-action
              ><v-icon>mdi-arrow-right</v-icon></v-list-item-action
            >
          </v-list-item>
        </div>
      </v-card>
    </v-bottom-sheet>
  </v-app>
</template>

<script>
import IntractAppBar from "@components/base/IntractAppBar";
import BottomBar from "@components/base/BottomBar";
import IntractSnackbar from "@components/base/IntractSnackbar";
import SplashScreen from "@components/base/SplashScreen";
import { mapActions, mapGetters } from "vuex";
import { App } from "@capacitor/app";
import api from "@api/index";
import endpoints from "@api/repository";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import EventBus from "@utils/event_bus";
import PullToRefresh from "pulltorefreshjs";
import Helper from "@utils/misc";
// import { codePush } from "capacitor-codepush";
// import Offline from "v-offline";

export default {
  name: "App",
  components: {
    // Offline,
    IntractAppBar,
    BottomBar,
    IntractSnackbar,
    SplashScreen,
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "accessToken",
      "currentInstitution",
      "onRefresh",
    ]),
    essentials() {
      return {
        accessToken: this.accessToken,
        handleErrorsFunction: this.handleApiError,
        setLoading: this.setLoading,
      };
    },
  },
  data: () => ({
    value: false,
    allKeepAliveRoutes: ["NoticeBoard", "Messages"],
    userDisabledDialog: false,
    window,
    showSupportSheet: false,

    //
  }),
  methods: {
    ...mapActions([
      "setLoading",
      "showSnackbar",
      "setCurrentUser",
      "setCurrentInstitution",
      "setAllRooms",
      "setAllBuses",
      "setUserSubjects",
      "setOnRefresh",
    ]),

    beforeLeave(el) {
      const { marginLeft, marginTop, width, height } =
        window.getComputedStyle(el);
      el.style.left = `${el.offsetLeft - parseFloat(marginLeft, 10)}px`;
      el.style.top = `${el.offsetTop - parseFloat(marginTop, 10)}px`;
      el.style.width = width;
      el.style.height = height;
    },

    async updateCurrentUserDetails() {
      var currentUserResponse = await api.call(
        this.essentials,
        endpoints.getMyDetails
      );
      if (currentUserResponse) {
        this.setCurrentUser(currentUserResponse);
      }

      var currentUserInstitutionResponse = await api.call(
        this.essentials,
        endpoints.getInstitutionDetailsById + this.currentInstitution.id + "/"
      );
      if (currentUserInstitutionResponse) {
        this.setCurrentInstitution(currentUserInstitutionResponse);
        if (currentUserInstitutionResponse.preferences.theme_primary)
          this.$vuetify.theme.themes.light.primary =
            currentUserInstitutionResponse.preferences.theme_primary;
      }

      if (this.currentUser.is_admin || this.currentUser.is_faculty) {
        var allRoomsResponse = await api.call(
          this.essentials,
          endpoints.getAllRooms
        );
        if (allRoomsResponse) {
          this.setAllRooms(allRoomsResponse);
        }
      }
      var allBusesResponse = await api.call(
        this.essentials,
        endpoints.allBuses
      );
      if (allBusesResponse) {
        this.setAllBuses(allBusesResponse);
      }

      // get all associated user subjects
      this.getAllUserSubjects();
    },

    async getAllUserSubjects() {
      var url = Helper.addUrlParams(endpoints.subjectViewSet, [
        this.currentUser.is_student
          ? "room=" + this.currentUser.room.id
          : "faculty=" + this.currentUser.id,
          "detailed=true"
      ]);

      var subjects = await api.call(this.essentials, url);
      this.setUserSubjects(subjects);
    },

    // async checkForUpdates() {
    //   const getDevice = () => import("@capacitor/device");
    //   const Device = (await getDevice()).Device;
    //   let platform = (await Device.getInfo()).platform;
    //   if (platform == "web") return;
    //   const SyncStatus = Object.freeze({
    //     UP_TO_DATE: 0,
    //     UPDATE_INSTALLED: 1,
    //     UPDATE_IGNORED: 2,
    //     ERROR: 3,
    //     IN_PROGRESS: 4,
    //     CHECKING_FOR_UPDATE: 5,
    //     AWAITING_USER_ACTION: 6,
    //     DOWNLOADING_PACKAGE: 7,
    //     INSTALLING_UPDATE: 8,
    //   });
    //   var self = this;
    //   console.log(codePush);

    //   codePush
    //     .sync({ installMode: 1 }, (p) => {
    //       console.log(p);
    //     })
    //     .then((status) => {
    //       switch (status) {
    //         case SyncStatus.DOWNLOADING_PACKAGE:
    //           //   // Show "downloading" modal
    //           self.showSnackbar({ title: "App is downloading" });
    //           break;
    //         case SyncStatus.INSTALLING_UPDATE:
    //           self.showSnackbar({ title: "App is being installed" });
    //           //   // Hide "downloading" modal
    //           break;
    //       }
    //     });

    //   // .then(function (update) {
    //   // if (!update) {
    //   //   self.showSnackbar({title: 'App is up to date!'});
    //   // } else {
    //   //   self.showSnackbar({title: 'Update is available!!!'});
    //   // }
    //   // });
    // },

    async handleApiError(err) {
      console.log(err);
      this.setLoading(false);
      if (err.response) {
        this.showSnackbar({
          title: "Something went wrong",
          text: err.response.data.message,
          type: "error",
        });
      } else {
        this.showSnackbar({
          title: "Unable to process request",
          type: "error",
        });
      }
    },

    async initFirebase() {
      const firebaseConfig = {
        apiKey: "AIzaSyAMV4J7O8P6cmPYvImbhCiy4pUtDVq3Ybc",
        authDomain: "ifi-app-f8eb1.firebaseapp.com",
        databaseURL: "https://ifi-app-f8eb1.firebaseio.com",
        projectId: "ifi-app-f8eb1",
        storageBucket: "ifi-app-f8eb1.appspot.com",
        messagingSenderId: "357571717904",
        appId: "1:357571717904:web:53fa6c99610dde95fb1395",
        measurementId: "G-VZE4KWME14",
      };

      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
      console.log(app, analytics);
    },
    async initKeepAliveRoutes() {
      // get all routes where keep alive is enabled
      this.allKeepAliveRoutes = this.$router.options.routes
        .filter((r) => r.meta.keepAlive)
        .map((r) => r.name);
      EventBus.$on("keep_alive__destroy_view", async (viewName) => {
        this.allKeepAliveRoutes = this.allKeepAliveRoutes.filter(
          (r) => r != viewName
        );
        await this.$nextTick();
        this.allKeepAliveRoutes.push(viewName);
      });
    },
  },
  watch: {
    $route(to) {
      this.setOnRefresh(null);
      if (this.currentInstitution) {
        document.title =
          ` ${to.meta.title} | ${this.currentInstitution.name} ` || "";
      } else document.title = to.meta.title;
    },
    onRefresh() {
      var refreshFunction = this.onRefresh;
      if (!refreshFunction) {
        PullToRefresh.destroyAll();
        return;
      }
      PullToRefresh.init({
        mainElement: "body",
        distMax: 200,
        distReload: 150,
        distIgnore: 30,
        distThreshold: 170,
        onRefresh() {
          refreshFunction();
        },
      });
    },
  },

  beforeDestroy() {
    PullToRefresh.destroyAll();
  },

  async created() {
    this.initFirebase();
    // this.checkForUpdates();
    if (this.currentUser && this.currentInstitution)
      await this.updateCurrentUserDetails();

    this.initKeepAliveRoutes();

    App.addListener("backButton", () => {
      if (this.$route.path == "/") App.exitApp();
      if (window.history.state != null) this.$router.back();
      else this.$router.push({ name: "Home" });
    });
  },
};
</script>

<style scoped>
.no-internet-connection-bottom {
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;

  left: 0;
  right: 0;
  margin: 0 auto;
  color: white;
  background-color: #f44336;
  border-radius: 5px;
  z-index: 50;
}

@media only screen and (max-width: 768px) {
  .no-internet-connection-bottom {
    width: 95%;
  }
}
@media only screen and (min-width: 768px) {
  .no-internet-connection-bottom {
    width: 300px;
    align-self: center;
  }
}
</style>
