<template>
  <div>
    <!-- splash -->

    <v-container class="px-1 py-2">
      <h2 class="mx-2 mb-1 text-md-center">{{ currentInstitution.name }}</h2>
      <v-row no-gutters class="mx-md-16 justify-md-center">
        <v-col
          v-for="feature in activeFeatures"
          :key="feature.id"
          cols="4"
          md="2"
        >
          <v-card
            @click="featureClicked(feature)"
            class="rounded text-center mx-1 py-4 my-1 my-md-2 non-focused"
          >

            <feature-svg v-if="feature.image" :name="feature.image" width="65px" height="65px" />
            <img v-else-if="feature.image_href" :src="getImageUrl(feature.image_href)" height="65px" width="65px" />
   
            <!-- <feature-svg :name="feature.image" width="65px" height="65px" /> -->
            <p class="feature-title mb-0 mt-2 font-weight-medium text-truncate">
              {{ feature.title }}
            </p>
          </v-card>
        </v-col>
      </v-row>
      <v-layout class="text-center mt-2" align-center justify-center>
        <img
          width="40px"
          class="d-inline mr-2"
          src="@assets/logo.png"
          alt="Intract Logo"
        />
        <div class="d-inline font-weight-medium">Powered by Intract</div>
      </v-layout>
    </v-container>

    <v-dialog v-model="userDetails.visible" persistent max-width="500px">
        
      <v-card class="text-center white--text py-4 px-2" color="primary">
        <h3>Please provide the following details to continue!</h3>
      </v-card>

    <v-card>
      <v-card-text class="px-4">
      <v-form>
        <v-list-item class="">
          <v-list-item-content>
            <div class="black--text subtitle-1 font-weight-bold">Set your profile picture
              <span class="red--text"><strong> * </strong></span>
              </div> 

            <template>  

              <v-col class="text-center">
                <div>
                  <v-avatar size="100px" class="">
                    <v-img
                      v-if="currentUser.image"
                      :src="currentUser.image"
                    ></v-img>
                  </v-avatar>
                </div>

                <v-btn
                  class="mt-2"
                  color="primary"
                  @click="$refs.profileImageField.$refs.input.click()"
                  ><v-icon class="mr-3">mdi-image</v-icon
                  ><span>Upload Image</span
                ></v-btn>

                <v-file-input
                  v-show="false"
                  class="ma-0 pa-0"
                  ref="profileImageField"
                  @change="(file) => addProfileImage(file)"
                  accept="image/*"
                ></v-file-input>
              </v-col>

            </template>
          </v-list-item-content>

        </v-list-item>
      
        <v-list-item class="">
          <v-list-item-content>
            <div class="black--text subtitle-1 font-weight-bold">Enter your address<span class="red--text"><strong> * </strong></span></div> 
            <v-text-field 
            :rules="[(v) => !!v || v != '' || 'Required']"
            required
            persistent-hint
            outlined
            hide-details="auto"
            v-model="currentUser.address"
            >
            </v-text-field>    
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="">
          <v-list-item-content>
            <div class="black--text subtitle-1 font-weight-bold">Set your birthday<span class="red--text"><strong> * </strong></span></div> 
            <div v-if="!currentUser.dob" class="red--text body-2">Not set</div>
            <div v-else class="red--text body-2">{{ currentUser.dob }}</div>
          </v-list-item-content>

          <v-list-item-icon class="align-self-center">
            <v-btn class="primary align-center" @click="userDetails.dob=true"> Set </v-btn>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item class="" v-if="currentUser.parent_profile">
          <v-list-item-content>
            <div class="black--text subtitle-1 font-weight-bold">Father's photo
              <span class="red--text"><strong> * </strong></span>
              </div> 

            <template>  

              <v-col class="text-center">
                <div>
                  <v-avatar size="100px" class="mb-2">
                    <v-img
                      v-if="currentUser.parent_profile.father_image"
                      :src="currentUser.parent_profile.father_image"
                    ></v-img>
                  </v-avatar>
                </div>

                <v-btn
                  class="mt-2"
                  color="primary"
                  @click="$refs.fatherImageField.$refs.input.click()"
                  ><v-icon class="mr-3">mdi-image</v-icon
                  ><span>Upload Image</span
                ></v-btn>

                <v-file-input
                  v-show="false"
                  class="ma-0 pa-0"
                  ref="fatherImageField"
                  @change="(file) => addFatherProfileImage(file)"
                  accept="image/*"
                ></v-file-input>
              </v-col>

            </template>
          </v-list-item-content>

        </v-list-item>

        <v-list-item class="" v-if="currentUser.parent_profile">
          <v-list-item-content>
            <div class="black--text subtitle-1 font-weight-bold">Father's name
            <span class="red--text"><strong> * </strong></span>
            </div> 
            <v-text-field 
            :rules="[(v) => !!v || v != '' || 'Required']"
            required
            hide-details="auto"
            persistent-hint
            outlined
            v-model="currentUser.parent_profile.father_name"
            >
            </v-text-field>    
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="" v-if="currentUser.parent_profile">
          <v-list-item-content>
            <div class="black--text subtitle-1 font-weight-bold">Mother's photo
              <span class="red--text"><strong> * </strong></span>
              </div> 

            <template>  

              <v-col class="text-center">
                <div>
                  <v-avatar size="100px" class="mb-2">
                    <v-img
                      v-if="currentUser.parent_profile.mother_image"
                      :src="currentUser.parent_profile.mother_image"
                    ></v-img>
                  </v-avatar>
                </div>

                <v-btn
                  class="my-2"
                  color="primary"
                  @click="$refs.motherImageField.$refs.input.click()"
                  ><v-icon class="mr-3">mdi-image</v-icon
                  ><span>Upload Image</span
                ></v-btn>

                <v-file-input
                  v-show="false"
                  class="ma-0 pa-0"
                  ref="motherImageField"
                  @change="(file) => addMotherProfileImage(file)"
                  accept="image/*"
                ></v-file-input>
              </v-col>

            </template>
          </v-list-item-content>

        </v-list-item>

        <v-list-item class="" v-if="currentUser.parent_profile">
          <v-list-item-content>
            <div class="black--text subtitle-1 font-weight-bold">Mother's name
            <span class="red--text"><strong> * </strong></span>  
            </div> 
            <v-text-field 
            :rules="[(v) => !!v || v != '' || 'Required']"
            required
            persistent-hint
            outlined
            hide-details="auto"
            v-model="currentUser.parent_profile.mother_name"
            >
            </v-text-field>    
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="">
          <v-list-item-content>
            <div class="black--text subtitle-1 font-weight-bold">Alternate Phone no<span class="red--text"><strong> * </strong></span></div> 
            <v-text-field 
            :rules="[(v) => !!v || v != '' || 'Required']"
            required
            persistent-hint
            outlined
            v-model="currentUser.fathers_phone"
            hide-details="auto"
            >
            </v-text-field>    
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="">
          <v-list-item-content>
            <div class="black--text subtitle-1 font-weight-bold">Blood Group</div> 
            <v-select
              :items="bloodGroupOptions"
              v-model="currentUser.blood_group"
              label="Outlined style"
              hide-details="auto"
              outlined
            ></v-select>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="">
          <v-list-item-content>
            <div class="black--text subtitle-1 font-weight-bold">Unique identity number</div> 
            <v-text-field 
            persistent-hint
            outlined
            hide-details="auto"
            v-model="currentUser.aadhar"
            placeholder="Aadhar/Birth Certificate no"
            >
            </v-text-field>    
          </v-list-item-content>
        </v-list-item>


        <!-- 'users/default/default.jpg' -->
        <v-list-item>
          <v-btn
            class="primary"
            block
            :disabled="
              !currentUser.dob || 
              !currentUser.address || 
              !currentUser.parent_profile.father_name ||
              !currentUser.parent_profile.mother_name || 
              !(currentUser.image && !currentUser.image.includes('users/default/default.jpg')) ||
              !(currentUser.parent_profile.father_image && !currentUser.parent_profile.father_image.includes('users/default/default.jpg')) ||
              !(currentUser.parent_profile.mother_image && !currentUser.parent_profile.mother_image.includes('users/default/default.jpg')) ||
              (false && !currentUser.aadhar) ||
              !currentUser.fathers_phone || 
              (false && !currentUser.blood_group)
            "
            @click="setProfileDetails"
          >Save</v-btn>
        </v-list-item>
        </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="userDetails.dob" persistent max-width="500px">
      <v-card class="text-center white--text py-4 px-2" color="primary">
        <h3>Please set your birthday!</h3>
      </v-card>
      <v-date-picker v-model="currentUser.dob" full-width>
        <v-spacer></v-spacer>

        <v-btn color="primary" @click="userDetails.dob=false" :disabled="!currentUser.dob">Ok</v-btn>
      </v-date-picker>
    </v-dialog>

    <image-cropper-dialog
      ref="cropperDialog"
      :uploadedImage="uploadedImage"
      @onCrop="(croppedImage) => {
        this.currentUser.image = croppedImage;
      }"
    />

    <image-cropper-dialog
      ref="fatherImageCropperDialog"
      v-if="this.currentUser.parent_profile"
      :uploadedImage="uploadedFatherImage"
      @onCrop="(croppedImage) => {
        this.currentUser.parent_profile.father_image = croppedImage;
      }"
    />

    <image-cropper-dialog
      ref="motherImageCropperDialog"
      v-if="this.currentUser.parent_profile"
      :uploadedImage="uploadedMotherImage"
      @onCrop="(croppedImage) => {
        this.currentUser.parent_profile.mother_image = croppedImage;
      }"
    />

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FeatureSvg from "@assets/images/FeatureSvg.vue";
// import Helper from "@utils/misc";
import Mixins from "@utils/mixins";
import ImageCropperDialog from "@components/dialogs/ImageCropperDialog"
import { Browser } from "@capacitor/browser";

import EventBus from "@utils/event_bus";
export default {
  name: "Home",
  components: {
    FeatureSvg,
    ImageCropperDialog,
  },
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  data() {
    return {
      platform: null,
      pullRefresh: null,
      // features: [
      //   { id: 1, title: "Messages", image: "messages", route: "Messages" },
      //   { id: 2, title: "Notice Board", image: "notice", route: "NoticeBoard" },
      //   { id: 22, title: "Books", image: "books", route: "Books" },
      //   { id: 17, title: "Fees", image: "books", route: "AllFeeStudents" },
      //   {
      //     id: 18,
      //     title: "Online Class",
      //     image: "live-lectures",
      //     route: "OnlineClasses",
      //   },
      //   // { id: 3, title: "Homework", image: "homework" },
      //   { id: 14, title: "Downloads", image: "downloads", route: "Downloads" },
      //   { id: 5, title: "Timetable", image: "timetable", route: "Timetable" },
      //   { id: 8, title: "Video Lectures", image: "messages", route: "AllVideoSubjects" },
      //   { id: 13, title: "Feedback", image: "downloads", route: "Feedback" },
      //   { id: 12, title: "Ask", image: "timetable", route: "Ask" },
      //   { id: 11, title: "Holidays", image: "downloads", route: "Holidays" },
      // ],

      // activeFeatures: [],
      PushNotifications: null,
      userDetails: {
        dob: false,
        address: false, 
        image: false, 
        visible: false,
        father_image: null,
        father_name: null,
        mother_name: null,
        mother_image: null,
        aadhar: null,
        blood_group: null,
        fathers_phone: null,
      }, 
      // visible: false,
      uploadedImage: null,
      uploadedFatherImage: null,
      uploadedMotherImage: null,
      bloodGroupOptions: [
        "A+",
        "A-",
        "B+",
        "B-",
        "O+",
        "O-",
        "AB+",
        "AB-"
      ]
    };
  },
  computed: {
    ...mapGetters(["currentUser","newNotificationsCount"]),

    features() {
      var self = this;
      return [
        { id: 1, title: "Messages", image_href: "messages.png", route: "Messages" },
        { id: 2, title: "Notice Board", image_href: "notice.png", route: "NoticeBoard" },
        { id: 3, title: "Homework", image_href: "homework.png", route: "Homework" },
        {
          id: 17,
          title: "Fees",
          image_href: "fees.png",
          action() {
            if (self.currentUser.is_student)
              self.$router.push({
                name: "StudentFeeDetails",
                params: { studentId: self.currentUser.id },
              });
            else self.$router.push({ name: "AllFeeStudents" });
          },
        },
        {
          id: 25,
          title: "Fees",
          image: "fees",
          action() {
            var url = self.endpoints.feesPortalLink + `/login/user/${self.currentInstitution.code}`;
            if (!url.includes("http")) url = "http://" + url;
            // window.open(url, "_newtab");
            Browser.open({ url: url });
          },
          hide: !this.currentUser.is_student,
        },
        { id: 22, title: "Books", image_href: "books.png", route: "Books" },

        {
          id: 8,
          title: "Video Lectures",
          image_href: "video-lectures.png",
          route: "AllVideoSubjects",
        },
        {
          id: 18,
          title: "Online Class",
          image_href: "live-lectures.png",
          route: "OnlineClasses",
        },
        {
          id: 19,
          title: "Tests",
          image_href: "tests.png",
          route: "AllOnlineTests",
        },
        {
          id: 20,
          title: "Attendance",
          image_href: "attendance.png",
          route: "Attendance",
        },
        { id: 5, title: "Timetable", image_href: "timetable.png", route: "Timetable" },
        {
          id: 6,
          title: "Gallery",
          image_href: "gallery.png",
          route: "Gallery",
        },
        { id: 7, title: "Bus", image_href: "bus-school.png", route: "BusLocation" },
        {
          id: 9,
          title: "Magazine",
          image_href: "magazine.png",
          route: "AllMagazinePosts",
        },
        { id: 10, title: "Exams", image_href: "exams.png", route: "AllExams" },
        { id: 4, title: "Events", image_href: "events.png", route: "AllEventsPosts" },
        { id: 11, title: "Holidays", image_href: "holidays.png", route: "Holidays" },
        { id: 12, title: "Ask", image_href: "ask.png", route: "Ask" },
        { id: 13, title: "Feedback", image_href: "feedback.png", route: "Feedback" },
        { id: 14, title: "Downloads", image_href: "downloads.png", route: "Downloads" },
        { id: 21, title: "Report Cards", image: "report-cards", route: "ReportCardDashboard" },
        { id: 23, title: "Circles", image: "circles", route: "AllCircleArticles" },
        { id: 24, title: "Planner", image_href: "Syllabus.png", route: "Planner" },
        { id: 26, title: "Activity Sheets", image: "activitySheets", route: "AllActivitySheetTopics" },
        {
          id: 15,
          title: "Website",
          image_href: "website.png",
          action() {
            var url = self.currentInstitution.website;
            if (!url.includes("http")) url = "http://" + url;
            // window.open(url, "_newtab");
            Browser.open({ url: url });
          },
          hide: !this.currentInstitution.website,
        },
      ];
    },

    activeFeatures() {
      var webRestrictedFeatures = [];
      var institutionFeatures = this.currentInstitution.features;
      if (
        institutionFeatures.includes("[") &&
        institutionFeatures.includes("]")
      ) {
        var parsedArray = JSON.parse(institutionFeatures); //parse
        // iterate over list and get matching values
        var validFeatures = this.features.filter(
          (i) => parsedArray.includes(i.id) && !i.hide
        );
        if (this.platform == "web") {
          validFeatures = validFeatures.filter(
            (i) => !webRestrictedFeatures.includes(i.id)
          );
        }
        return validFeatures;
      }
      return [];
    },
  },
  methods: {
    ...mapActions([
      "showSnackbar",
      "setCurrentUser",
      "setCurrentInstitution",
      "setLoading",
      "setNewNotificationsCount",
    ]),
    async setBirthday() {
      var url = this.endpoints.userProfileViewSet + this.currentUser.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.PATCH,
        { dob: this.currentUser.dob }
      );
      if (response) {
        this.userDetails.dob = false;
      }
    },

    async setProfileDetails() {
      var url = this.endpoints.userProfileViewSet + this.currentUser.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.PATCH,
        { 
          image: this.currentUser.image, 
          address: this.currentUser.address, 
          dob: this.currentUser.dob,
          father_image: this.currentUser.parent_profile.father_image,
          mother_image: this.currentUser.parent_profile.mother_image, 
          father_name: this.currentUser.parent_profile.father_name,
          mother_name: this.currentUser.parent_profile.mother_name,
          fathers_phone: this.currentUser.fathers_phone,
          blood_group: this.currentUser.blood_group,
          aadhar: this.currentUser.aadhar
        }
      );
      if (response) {
        this.userDetails.visible = false;
      }
    },

    async featureClicked(feature) {
      if (feature.route) this.$router.push({ name: feature.route });
      else feature.action();
    },

    getImageUrl(image) {
      return require('../assets/features/' + image);
    },

    async updateCurrentUserDetails() {
      var currentUserResponse = await this.api.call(
        this.essentials,
        this.endpoints.getMyDetails
      );
      if (currentUserResponse) {
        this.setCurrentUser(currentUserResponse);
      }

      var currentUserInstitutionResponse = await this.api.call(
        this.essentials,
        this.endpoints.getInstitutionDetailsById +
          this.currentInstitution.id +
          "/"
      );
      if (currentUserInstitutionResponse) {
        this.setCurrentInstitution(currentUserInstitutionResponse);
      }
    },

    

    async handleApiError(err) {
      console.log(err);
      this.setLoading(false);
      if (err.response) {
        this.showSnackbar({
          title: "Something went wrong",
          text: err.response.data.message,
          type: "error",
        });
      } else {
        this.snackbar.text = "Unable to process request";
        this.snackbar.visible = true;
      }
    },

    async initPush() {
      if (this.PushNotifications) this.PushNotifications.removeAllListeners();

      // code to avoid imports
      if (this.platform == "web") return;
      const getPushNotifications = () => import("@capacitor/core");
      this.PushNotifications = (
        await getPushNotifications()
      ).Plugins.PushNotifications;

      // register
      this.PushNotifications.requestPermissions().then((permission) => {
        console.log(permission);
        if (permission.receive == "granted") {
          // Register with Apple / Google to receive push via APNS/FCM
          this.PushNotifications.register();
        } else {
          // No permission for push granted
          this.showSnackbar({
            title:
              "Notification has been disabled. To enable again, kindly restart the app",
            type: "error",
          });
        }
      });

      this.PushNotifications.addListener("registration", async (token) => {
        console.log(token);
        var response = await this.api.call(
          this.essentials,
          this.endpoints.saveFCMToken,
          this.api.Methods.POST,
          { fcm_token: token.value }
        );
        if (response) console.log("fcm saved");
      });

      this.PushNotifications.addListener("registrationError", (error) => {
        console.log("Error: " + JSON.stringify(error));
      });

      this.PushNotifications.addListener(
        "pushNotificationReceived",
        async (notification) => {
          EventBus.$emit(
            "notifications__new_notification_received",
            notification
          );
          // self.setNewNotificationsCount(this.newNotificationsCount + 1);
          console.log("Push received: ", notification);
        }
      );

      this.PushNotifications.addListener(
        "pushNotificationActionPerformed",
        async (notification) => {
          const data = notification.notification.data;
          console.log(data);
          if (data && data.navigator) {
            var route = JSON.parse(data.navigator);
            if (route) this.$router.push(route);
          }
          console.log("Action performed", notification);
        }
      );
    },
    async onRefresh() {
      this.showSnackbar({ title: "Refreshing...", type: "info" });
      setTimeout(() => window.location.reload(), 500);
    },

    async addProfileImage(file) {
      if (!file) return;
      // var image_object = {
      //   document: await this.Helper.toBase64(file),
      //   file_name: file.name,
      // };
      this.uploadedImage = await this.Helper.toBase64(file);
      this.$refs.cropperDialog.initCropper(file.type);
      // console.log(this.currentUser.image)
      // this.$set(this.currentUser.image, "image", image_object.document);
    },

    async addFatherProfileImage(file){
      if (!file) return;
      this.uploadedFatherImage = await this.Helper.toBase64(file);
      this.$refs.fatherImageCropperDialog.initCropper(file.type);
    },

    async addMotherProfileImage(file){
      if (!file) return;
      this.uploadedMotherImage = await this.Helper.toBase64(file);
      this.$refs.motherImageCropperDialog.initCropper(file.type);
    }
  },

  async created() {
    this.platform = await this.Helper.getDevicePlatform();
    // this.activeFeatures = await this.getActiveFeatures();
    this.initPush();
    if(this.currentInstitution.preferences.app_student_extra_data){
      if (
        this.currentUser.is_student && 
          (
            !this.currentUser.dob || 
            !this.currentUser.address || 
            !(
              this.currentUser.image && 
              !this.currentUser.image.includes('users/default/default.jpg')
            ) ||
            !(
              this.currentUser.parent_profile.mother_image && 
              !this.currentUser.parent_profile.mother_image.includes('users/default/default.jpg')
            ) ||
            !(
              this.currentUser.parent_profile.father_image && 
              !this.currentUser.parent_profile.father_image.includes('users/default/default.jpg')
            ) ||
            !this.currentUser.parent_profile.mother_name ||
            !this.currentUser.parent_profile.father_name ||
            // !this.currentUser.aadhar ||
            // !this.currentUser.blood_group ||
            !this.currentUser.fathers_phone
          )
        ){
        // this.visible = true;
        this.userDetails.visible = true;
      }
    }
    // this.updateCurrentUserDetails();
    // Helper.initPullRefresh(this.updateCurrentUserDetails);
    // this.checkForUpdates();
  },

  beforeDestroy() {
    console.log("beforeDestroy");
    // Helper.destroyPullRefresh();
    if (this.PushNotifications) this.PushNotifications.removeAllListeners();
  },
};
</script>
<style scoped>
.feature-title {
  font-size: 15px;
}
</style>
